<script setup lang="ts">
defineProps<{
  side: "right" | "bottom";
}>();

const sidebarController = useSideMenuModal();

const { isOpen, close } = sidebarController;

const sidebarContentElement = ref();
onClickOutside(sidebarContentElement, () => close());
</script>

<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="isOpen"
        class="fixed z-30 inset-0 overflow-hidden bg-black bg-opacity-50"
      >
        <div
          class="flex items-start justify-center md:justify-end min-h-screen pt-24 text-center"
        >
          <Transition
            enter-active-class="transition ease-out duration-200 transform"
            :name="side === 'bottom' ? 'slide-up' : 'slide-left'"
          >
            <div
              v-show="isOpen"
              key="sidebar"
              class="pointer-events-none fixed inset-y-0 flex max-w-full"
              :data-testid="`sidebar-${side}`"
              :class="{
                'right-0': side === 'right',
                'bottom-0': side === 'bottom',
              }"
            >
              <div
                ref="sidebarContentElement"
                class="pointer-events-auto w-screen md:max-w-[31rem]"
                :class="{
                  'h-screen': side !== 'bottom',
                  'h-auto': side === 'bottom',
                }"
              >
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <slot></slot>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
